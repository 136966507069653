import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const leftDays = (valid_until, valid_from = new Date()) => {
  const dateJS = dayjs(valid_until)
  return dateJS.diff(valid_from, 'day')
}

export const orderAmount = (monthly_price, daily_price, valid_until, valid_from = new Date()) => {
  const dateJS = dayjs(valid_until)
  let days = dateJS.diff(dayjs(valid_from), 'day')
  let months = dateJS.diff(dayjs(valid_from), 'month')
  if (days === 0) {
    return monthly_price?.toFixed(2)
  }
  if (months >= 1) {
    return monthly_price?.toFixed(2)
  }
  return (days * daily_price > monthly_price ? monthly_price : days * daily_price).toFixed(2)
}

export const calcIsDaily = (valid_until, valid_from = new Date()) => {
  return dayjs(valid_until).diff(dayjs(valid_from), 'month') === 0
}

export const orderPrice = (monthly_price, daily_price, valid_until, valid_from = new Date()) => {
  return orderAmount(monthly_price, daily_price, valid_until, valid_from) + ' €'
}

export const validPhone = (phone) => {
  let regex = /^\+[1-9]\d{1,14}$/;
  return !!(phone && phone.match(regex));
}

export const expireDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)

export const calculateDateDifference = (start, end) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const minutes = Math.ceil(endDate.diff(startDate, 'minute', true));
  const diffInHours = Math.ceil(endDate.diff(startDate, 'hour', true));
  let diffInMonths = endDate.diff(startDate, 'month');

  let diffInDays = endDate.diff(startDate, 'day', true);
  if (diffInDays < 1)
    diffInDays = 1

  if (diffInMonths < 1)
    diffInMonths = 1

  const remainingMinutes = (minutes % 60).toString().padStart(2, '0');

  return {
    HOURLY: {
      show: `${Math.floor(minutes / 60)}:${remainingMinutes}`,
      calc: diffInHours
    },
    DAILY: {
      show: diffInDays,
      calc: diffInDays
    },
    MONTHLY: {
      show: diffInMonths,
      calc: diffInMonths
    }
  };
}

export const DAILY = 'DAILY'
export const HOURLY = 'HOURLY'

export const GATE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}
export const isAccessHours = () => {
  const currentTime = dayjs();
  const openTime = dayjs().hour(6).minute(0).second(0);
  const closeTime = dayjs().hour(22).minute(0).second(0);
  const isWithinOperatingHours = currentTime.isBetween(openTime, closeTime, null, '[]');

  const currentDay = currentTime.day();
  const isWeekday = currentDay >= 1 && currentDay <= 5;

  return isWithinOperatingHours && isWeekday
}
